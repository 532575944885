import { Checkbox, FormProvider, useFormContext } from '@fleet/shared';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  AdditionalRecipientsAddBtn,
  AdditionalRecipientsSelector,
} from 'components/ticketFulfillment/AdditionalRecipientsSelector';
import { TicketFulfillmentSelection } from 'components/ticketFulfillment/TicketFulfillmentSelection';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import type { FC } from 'react';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'store/utils';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: '15rem',
    },
    bgGray: {
      background: theme.palette.background.default,
    },
    addBtn: {
      alignSelf: 'flex-end',
    },
  }),
  {
    name: 'SmsFulfillment',
  }
);

interface SmsFulfillmentProps {
  formId?: string;
  purchaserPhone?: string;
  isInline?: boolean;
  onSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => void;
}

export const SmsFulfillment: FC<SmsFulfillmentProps> = ({
  formId,
  onSubmit,
  isInline,
  purchaserPhone,
}) => {
  const form = useFormContext();
  const { purchaser } = useSelector(currentBookingSelector)!;
  const classes = useStyles();
  const phone =
    purchaserPhone ?? purchaser?.contactInformation.phoneNumber.value;
  const sendToPayerLabel = useMemo(
    () =>
      renderToString(
        <TransLabel
          i18nKey="sendToPayer"
          values={{ postfix: phone && `(${phone})` }}
        />
      ),
    [phone]
  );
  const onSendToPayerChange = useCallback(
    (isChecked: boolean) => {
      form.change('smsConfirmationRecipient', isChecked ? [phone] : undefined);
    },
    [form, phone]
  );

  return (
    <Card elevation={0} className={classes.root}>
      <FormProvider form={form}>
        <CardContent
          id={formId}
          component="form"
          className={classNames({ [classes.bgGray]: isInline })}
          onSubmit={onSubmit}
        >
          <Grid container columns={2} spacing={2} rowSpacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle">
                <TransSubtitle i18nKey="sendBookingConfirmationViaSms" />
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Stack spacing={2}>
                <Typography variant="subtitle" color="primary.main">
                  <TransSubtitle i18nKey="smsRecipient" />
                </Typography>
                <Stack direction="row">
                  {phone && (
                    <Checkbox
                      onChange={(e) => onSendToPayerChange(e.target.checked)}
                      label={sendToPayerLabel}
                    />
                  )}
                  <AdditionalRecipientsAddBtn name="additionalSmsConfirmationRecipients" />
                </Stack>
                <Divider sx={{ my: 2 }} />
                <AdditionalRecipientsSelector
                  name="additionalSmsConfirmationRecipients"
                  type="sms"
                />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <TicketFulfillmentSelection type="sms" />
            </Grid>
          </Grid>
        </CardContent>
      </FormProvider>
    </Card>
  );
};
