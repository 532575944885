import { FormProvider, useFormContext } from '@fleet/shared';
import { CheckboxGroupField } from '@fleet/shared/form';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  AdditionalRecipientsAddBtn,
  AdditionalRecipientsSelector,
} from 'components/ticketFulfillment/AdditionalRecipientsSelector';
import { TicketFulfillmentSelection } from 'components/ticketFulfillment/TicketFulfillmentSelection';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { selectPosConfiguration } from 'features/user/userSelector';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'store/utils';

interface EmailFulfillmentProps {
  formId?: string;
  purchaserEmail?: string;
  isInline?: boolean;
  isSaleFlow?: boolean;
  onSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: '15rem',
    },
    sectionHeader: {
      height: '1.5rem',
      '& + *': {
        marginTop: '1rem',
      },
    },
    indicator: {
      height: 1,
    },
    hidden: {
      display: 'none',
    },
    tabs: {
      padding: '0 1rem',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    bgGray: {
      background: theme.palette.background.default,
    },
    addBtn: {
      alignSelf: 'flex-end',
    },
    ticketsSwitch: {
      '& .MuiFormControl-root': {
        width: 'auto',
      },
    },
    selectionControl: {
      minWidth: 'none',
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
      padding: 0,
    },
  }),
  { name: 'SendTicketsSelection' }
);

export const EmailFulfillment: FC<EmailFulfillmentProps> = ({
  formId,
  purchaserEmail,
  isInline,
  onSubmit,
  isSaleFlow,
}) => {
  const form = useFormContext();
  const posConfiguration = useSelector(selectPosConfiguration);
  const classes = useStyles();
  const { purchaser, passengers } = useSelector(currentBookingSelector)!;
  const purchaserOptions = useMemo(() => {
    const email =
      purchaserEmail ?? purchaser?.contactInformation.emailAddress.value;
    return [
      {
        label: (
          <TransSubtitle
            i18nKey="sendToPayer"
            values={{
              email,
            }}
          />
        ),
        value: email,
      },
    ];
  }, [purchaser, purchaserEmail]);

  useEffect(() => {
    if (!posConfiguration || !posConfiguration.purchaseConfirmationEnabled)
      return;

    const initialFields = {
      includeTickets: !!isSaleFlow,
      passengerSelection: [],
      emailConfirmationRecipient: isSaleFlow ? [purchaserOptions[0].value] : [],
    };

    Object.entries(initialFields).forEach(([fieldName, value]) => {
      form.change(fieldName, value);
    });
  }, [form, purchaserOptions, posConfiguration, isSaleFlow]);

  const passengerOptions = useMemo(
    () =>
      passengers.map(
        ({
          firstName,
          lastName,
          contactInformation: { emailAddress },
          id,
        }) => ({
          label: `${firstName.value} ${lastName.value} (${emailAddress.value})`,
          value: id,
        })
      ),
    [passengers]
  );

  const getPassengerSelectionHandler = useCallback(
    (selection: 'all' | 'none') => () => {
      form.change(
        'passengerSelection',
        selection === 'all' ? passengers.map(({ id }) => id) : []
      );
    },
    [form, passengers]
  );

  return (
    <Card elevation={0} className={classes.root}>
      <FormProvider form={form}>
        <CardContent
          id={formId}
          component="form"
          className={classNames({ [classes.bgGray]: isInline })}
          onSubmit={onSubmit}
        >
          <Typography variant="subtitle" className={classes.sectionHeader}>
            <TransSubtitle i18nKey="sendBookingConfirmationViaEmail" />
          </Typography>
          <Grid container columns={2} spacing={1}>
            {posConfiguration?.purchaseConfirmationEnabled && (
              <Grid item xs={1}>
                <Stack gap={1}>
                  <Typography variant="subtitle" color="primary.main">
                    <TransSubtitle i18nKey="emailRecipient" />
                  </Typography>
                  <Stack direction="row" justifyContent="space-aroung">
                    <>
                      {(purchaserEmail ||
                        purchaser?.contactInformation.emailAddress.value) && (
                        <CheckboxGroupField
                          name="emailConfirmationRecipient"
                          options={purchaserOptions}
                        />
                      )}
                    </>
                    <AdditionalRecipientsAddBtn name="additionalEmailConfirmationRecipients" />
                  </Stack>
                  <Divider />
                  <AdditionalRecipientsSelector
                    name="additionalEmailConfirmationRecipients"
                    type="email"
                  />
                </Stack>
              </Grid>
            )}
            {posConfiguration?.ticketDeliveryEnabled && (
              <Grid item xs={1} paddingTop={1}>
                <TicketFulfillmentSelection type="email" />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </FormProvider>
    </Card>
  );
};
