import { Icon, useModal } from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { Button } from '@fleet/shared/mui';
import { Divider, Stack, Typography } from '@mui/material';
import { AlertCard } from 'components/AlertCard';
import { BookingTotalFee } from 'components/BookingTotalFee';
import { CartTotal } from 'components/CartTotal';
import { ModifyFees } from 'components/ModifyFees';
import { ModifyJourneyStepsContext } from 'components/ModifyJourneyStepsProvider';
import { PayerDetailsForm } from 'components/PayerDetailsForm';
import { PaymentMethod, PaymentMethods } from 'components/PaymentMethods';
import { TicketFulfillment } from 'components/ticketFulfillment/TicketFulfillment';
import { FulfillmentStatus } from 'dto/booking';
import {
  clearExchangeOperations,
  getBooking,
  getHistory,
} from 'features/booking/bookingActions';
import {
  bookingExpiredSelector,
  currentBookingSelector,
  currentTripsSelector,
  isTravelPassBookingSelector,
} from 'features/booking/bookingSelectors';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { updateTab } from 'features/tabs/tabsActions';
import { PurchaserDetailsPayload } from 'features/trip/tripActions';
import { paymentStatusSelector } from 'features/trip/tripSelector';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ModalControlsWrap } from 'routes/bookingDetails/modal/ModalControlsWrap';
import { ModalWrap } from 'routes/bookingDetails/modal/ModalWrap';
import { Comments } from 'routes/bookingDetails/tabs/Comments';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';
import { PayByLinkModal } from 'routes/tickets/checkout/PayByLinkModal';
import { TravelPassOverview } from 'routes/tickets/checkout/TravelPassOverview';
import { useDispatch, useSelector } from 'store/utils';
import {
  IS_DS_AT,
  IS_IMS_AT,
  FEATURE_SEND_EMAIL_DEFAULT,
  FEATURE_SEND_SMS_DEFAULT,
} from 'utils/flags';
import {
  useConfirmationSend,
  usePurchaserInfo,
  useSubmitBooking,
} from 'utils/overview';
import { getOnDemandServiceTexts, getTripAdmissions } from 'utils/trip';
import { useHistory } from 'react-router-dom';
import { activeTabSelector } from 'features/tabs/tabsSelector';
import { OnHoldBookingModal } from 'components/OnHoldBookingModal';

export interface TicketSelectionPayload {
  emailConfirmationRecipient: Array<string>;
  additionalEmailConfirmationRecipients: Array<string>;
  smsConfirmationRecipient: Array<string>;
  additionalSmsConfirmationRecipients: Array<string>;
  passengers?: Array<{
    passengerId: string;
    sendSms: boolean;
    sendEmail: boolean;
    currentPhone: string;
    currentEmail: string;
    currentPhoneOverride?: string;
    currentEmailOverride?: string;
    phoneNumberOverride?: Array<string>;
    emailOverride?: Array<string>;
  }>;
}

interface OverviewProps {
  goToNextStep: () => void;
  isModifyFlow?: boolean;
  submitLabel?: ReactNode;
}

export const Overview: FC<OverviewProps> = ({
  goToNextStep,
  submitLabel,
  isModifyFlow,
}) => {
  const currentTab = useSelector(activeTabSelector);
  const [isEmailSelected, setEmailSelected] = useState(
    FEATURE_SEND_EMAIL_DEFAULT
  );
  const [isSmsSelected, setSmsSelected] = useState(FEATURE_SEND_SMS_DEFAULT);
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState(
    IS_DS_AT ? PaymentMethod.external : ''
  );
  const { open, onOpen, onClose } = useModal();
  const { closeModal } = useContext(ModifyJourneyStepsContext);
  const booking = useSelector(currentBookingSelector)!;
  const currentTrips = useSelector(currentTripsSelector);
  const showPaymentMethods = useMemo(() => {
    return (
      !isModifyFlow ||
      !booking.bookedTrips
        .map((trip) => getTripAdmissions(trip))
        .flat()
        .some(({ status }) => status === FulfillmentStatus.ON_HOLD)
    );
  }, [booking.bookedTrips, isModifyFlow]);
  const paymentStatus = useSelector(paymentStatusSelector);
  const dispatch = useDispatch();
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const loading = useSelector(bookingCheckoutLoadingSelector);
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const { bookedTrips } = booking;
  const totalToPay = useMemo(() => {
    if (booking?.provisionalPrice) {
      return `${booking.provisionalPrice.amount} ${booking.provisionalPrice.currency}`;
    }
  }, [booking]);
  const handleConfirmationSend = useConfirmationSend(booking.id);
  const { form: ticketFulfillmentForm } = useForm<TicketSelectionPayload>({
    onSubmit: handleConfirmationSend,
    initialValues: useMemo<Partial<TicketSelectionPayload>>(
      () => ({
        passengers:
          booking?.passengers.map(({ id, contactInformation }) => ({
            passengerId: id,
            currentEmail: contactInformation.emailAddress.value,
            currentPhone: contactInformation.phoneNumber.value,
            sendSms: false,
            sendEmail: false,
          })) ?? [],
      }),
      [booking?.passengers]
    ),
    subscription: { invalid: true },
  });
  const showSuccessPage = useCallback(async () => {
    const isPayByLink = paymentMethod === PaymentMethod.adyen;
    dispatch(updateTab({ isCompleted: true }));
    isPayByLink && ticketFulfillmentForm.submit();
    if (isPayByLink && isModifyFlow) {
      dispatch(clearExchangeOperations());
      dispatch(getBooking(booking.id));
      dispatch(getHistory(booking.id));
      closeModal();
    }
    goToNextStep();
  }, [
    booking.id,
    closeModal,
    dispatch,
    goToNextStep,
    isModifyFlow,
    paymentMethod,
    ticketFulfillmentForm,
  ]);
  const onBookingSubmit = useSubmitBooking({
    bookingId: booking.id,
    ticketFulfillmentForm,
    paymentMethod,
    showSuccessPage,
    isModifySubmit: isModifyFlow,
  });
  const onModifySubmit = useCallback(
    async (values) => {
      await onBookingSubmit(values);
      if (paymentMethod !== PaymentMethod.adyen) {
        dispatch(clearExchangeOperations());
        dispatch(getBooking(booking.id));
        dispatch(getHistory(booking.id));
        closeModal();
      }
    },
    [booking.id, closeModal, dispatch, onBookingSubmit, paymentMethod]
  );

  const {
    form: payerDetailsForm,
    handleSubmit,
    values,
    invalid,
  } = useForm<PurchaserDetailsPayload>({
    initialValues: useMemo(
      () => currentTab.purchaserData || {},
      [currentTab.purchaserData]
    ),
    subscription: { values: true, invalid: true },
    onSubmit: isModifyFlow ? onModifySubmit : onBookingSubmit,
  });
  const purchaserInfo = usePurchaserInfo(payerDetailsForm, values);
  const onDemandTexts = bookedTrips ? getOnDemandServiceTexts(bookedTrips) : [];

  const resetAllFields = useCallback(() => {
    payerDetailsForm.restart();
    ticketFulfillmentForm.restart();
    setSmsSelected(false);
    setEmailSelected(false);
  }, [payerDetailsForm, ticketFulfillmentForm]);

  return (
    <>
      <Stack spacing={2} divider={<Divider />}>
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              <TransTitle i18nKey="overview" />
            </Typography>
            <Button
              variant="text"
              startIcon={<Icon name="comment" />}
              onClick={onOpen}
              label={<TransButton i18nKey="addViewComments" />}
            />
          </Stack>

          {!!onDemandTexts.length && (
            <AlertCard
              title={<TransAlert i18nKey="advanceOrderRequired" />}
              message={onDemandTexts}
            />
          )}
          {isTravelPassBooking ? (
            <>
              <TravelPassOverview />
              <BookingTotalFee isOverview />
            </>
          ) : (
            <>
              <Stack spacing={1}>
                {currentTrips.map((trip) => (
                  <JourneyOverview isOverview trip={trip} key={trip.id} />
                ))}
                {isModifyFlow ? <ModifyFees /> : <BookingTotalFee isOverview />}
              </Stack>
              {!isModifyFlow && IS_IMS_AT && (
                <Button
                  variant="outlined"
                  sx={{ alignSelf: 'flex-start' }}
                  startIcon={<Icon name="add" />}
                  label={<TransButton i18nKey="addJourney" />}
                  onClick={() => history.replace('/search')}
                />
              )}
            </>
          )}
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ py: 3 }}
          >
            <Typography variant="h2">
              <TransField i18nKey="totalToPay" />:
            </Typography>
            <Typography variant="title">{totalToPay}</Typography>
          </Stack>
        </>
        <PayerDetailsForm form={payerDetailsForm} handleSubmit={handleSubmit} />
        {showPaymentMethods && (
          <>
            <TicketFulfillment
              ticketFulfillmentForm={ticketFulfillmentForm}
              isPassengerDetailsFormInvalid={invalid}
              purchaserInfo={purchaserInfo}
              isSaleFlow={true}
              isEmailSelected={isEmailSelected}
              isSmsSelected={isSmsSelected}
              setSmsSelected={setSmsSelected}
              setEmailSelected={setEmailSelected}
            />
            <PaymentMethods
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </>
        )}
      </Stack>
      {isModifyFlow ? (
        <ModalControlsWrap>
          <Button
            variant="text"
            label={<TransButton i18nKey="cancel" />}
            onClick={closeModal}
          />
          <Button
            variant="contained"
            type="submit"
            form="purchaserDetails"
            loading={loading}
            disabled={showPaymentMethods && !paymentMethod}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                {submitLabel}
              </>
            }
          />
        </ModalControlsWrap>
      ) : (
        <CartTotal>
          <>
            <OnHoldBookingModal
              goToNextStep={goToNextStep}
              payerDetailsForm={payerDetailsForm}
            />
            <Button
              variant="text"
              onClick={resetAllFields}
              label={<TransButton i18nKey="resetFields" />}
              disabled={isBookingExpired}
            />
            <Button
              variant="contained"
              type="submit"
              loading={loading}
              disabled={isBookingExpired || !paymentMethod}
              form="purchaserDetails"
              label={
                <>
                  <Icon name="check" sx={{ mr: 1 }} />
                  {submitLabel}
                </>
              }
            />
          </>
        </CartTotal>
      )}
      {IS_IMS_AT && paymentStatus && (
        <PayByLinkModal onPaymentSuccess={showSuccessPage} />
      )}
      <ModalWrap
        open={open}
        onClose={onClose}
        title={
          <Typography variant="subtitle">
            <TransSubtitle i18nKey="comments" />
          </Typography>
        }
        maxWidth="xl"
      >
        <Comments isSalesFlow />
      </ModalWrap>
    </>
  );
};
